import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../../components/Layouts/Default'

import Game from '../../../components/Game'
import Bento from '../../../games/bento/main'
import Seo from '../../../components/Seo'

const NATIVE_WIDTH = 768
const NATIVE_HEIGHT = 1024
const TOP_NAV_HEIGHT = 40

class KanaBento extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      game: null,
      focusKana: 'hiragana',
      gridKana: 'romaji',
      grid: {
        romaji: [
          ['wa', 'ra', 'ya', 'ma', 'ha', 'na', 'ta', 'sa', 'ka', 'a'],
          ['', 'ri', '', 'mi', 'hi', 'ni', 'chi', 'shi', 'ki', 'i'],
          ['wo', 'ru', 'yu', 'mu', 'fu', 'nu', 'tsu', 'su', 'ku', 'u'],
          ['', 're', '', 'me', 'he', 'ne', 'te', 'se', 'ke', 'e'],
          ['n', 'ro', 'yo', 'mo', 'ho', 'no', 'to', 'so', 'ko', 'o'],
        ],
        hiragana: [
          ['わ', 'ら', 'や', 'ま', 'は', 'な', 'た', 'さ', 'か', 'あ'],
          ['', 'り', '', 'み', 'ひ', 'に', 'ち', 'し', 'き', 'い'],
          ['を', 'る', 'ゆ', 'む', 'ふ', 'ぬ', 'つ', 'す', 'く', 'う'],
          ['', 'れ', '', 'め', 'へ', 'ね', 'て', 'せ', 'け', 'え'],
          ['ん', 'ろ', 'よ', 'も', 'ほ', 'の', 'と', 'そ', 'こ', 'お'],
        ],
        katakana: [
          ['ワ', 'ラ', 'ヤ', 'マ', 'ハ', 'ナ', 'タ', 'サ', 'カ', 'ア'],
          ['', 'リ', '', 'ミ', 'ヒ', 'ニ', 'チ', 'シ', 'キ', 'イ'],
          ['ヲ', 'ル', 'ユ', 'ム', 'フ', 'ヌ', 'ツ', 'ス', 'ク', 'ウ'],
          ['', 'レ', '', 'メ', 'ヘ', 'ネ', 'テ', 'セ', 'ケ', 'エ'],
          ['ン', 'ロ', 'ヨ', 'モ', 'ホ', 'ノ', 'ト', 'ソ', 'コ', 'オ'],
        ],
      },
    }

    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.render = this.render.bind(this)

    this._handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    const script = document.createElement('script')

    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/phaser/2.6.2/phaser.min.js'
    script.async = true
    script.addEventListener('load', () => {
      this.handleScriptLoad()
    })
    script.addEventListener('error', (e) => {
      this.handleScriptError()
    })

    document.body.appendChild(script)

    this.handleResize()
    this.windowListener = window.addEventListener('resize', this._handleResize)

    return () => {
      document.body.removeChild(script)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize)

    if (this && this.state && this.state.game) {
      this.state.game.destroy()
    } else {
      console.log('Unsuccessfully tried to destroy Kana Bento', this.state)
    }
  }

  handleScriptError() {
    this.forceUpdate()
  }

  handleScriptLoad() {
    const kanaBento = Object.create(Bento)
    this.setState({
      game: kanaBento,
    })

    if (this && this.state) {
      kanaBento.init({
        focusKana: this.state.focusKana,
        gridKana: this.state.gridKana,
        grid: this.state.grid,
      })
    } else {
      console.log('Kana bento state no longer exists, move on without creating game')
      // debugger
    }
  }

  handleResize() {
    const w = window.innerWidth
    const h = window.innerHeight - TOP_NAV_HEIGHT
    const scale = Math.min(w / NATIVE_WIDTH, h / NATIVE_HEIGHT)
    const width = Math.round(scale * NATIVE_WIDTH)
    const height = Math.round(scale * NATIVE_HEIGHT)
    this.setState({
      width: width,
      height: height,
    })
  }

  render() {
    return (
      <Layout>
        <div>
          <Seo meta="hiragana-kana-bento" />
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <div className="kana-bento">
            <Game width={this.state.width} height={this.state.height} />
          </div>
          <hr />
          <div className="game-info basic">
            <h1 className="center h3">{'Drag and Drop Hiragana game'}</h1>
            <p>{`This Hiragana drag-n-drop game is for the Japanese alphabet (more correctly syllabic script) Hiragana. Drag and drop Hiragana kanas on to a Romaji kana grid.`}</p>
            <p>
              {`Kana Bento was inspired by the Hiragana drag-n-drop game by Sacramento State's Andrea Shea and Professor Masuyama located`}
              <a
                href="http://www.csus.edu/indiv/s/sheaa/projects/genki/hiragana-timer.html"
                rel="nofollow"
              >{` here.`}</a>
            </p>
            <h4 className="center">{`How to play Kana Bento Hiragana Drag and Drop`}</h4>
            <p>
              {`You are presented with a `}
              <em>{`target`}</em>
              {` set of shuffled Hiragana kanas at the bottom of the screen, and a Romaji `}
              <em>{`grid `}</em>
              {` at the top of the screen. Drag-n-drop all the target Hiragana characters onto the appropriate Romaji kanas. You can optionally limit the rows of Kana you play by clicking the green ticks above the grid.`}
            </p>
            <p>{`The more you mix and match, the more you challenge your memory recall, and the more you play the better you will memorize Hiragana.`}</p>
            <p>{`Have fun!`}</p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default KanaBento
